export const postBearers = [
  {
    id: 1,
    name: 'Satyam Kumar',
    post: 'President',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238651/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20240821_220833_685_ve5h27.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/satyam-kumar-582a58256',
  },
  {
    id: 2,
    name: 'Tushit Arya',
    post: 'Secretary',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238692/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250217-WA0035_dhedr8.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/tushit-arya-828977257',
  },
  {
    id: 3,
    name: 'Sarthak Ranjan',
    post: 'Vice President',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238679/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20250112_210223_mptuvp.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/sarthak-ranjan-099b39266',
  },
  {
    id: 4,
    name: 'Nidhishree Mahato',
    post: 'Vice President',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238800/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250112-WA0014_u7kkg1.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/nidhishree-mahato-9118a125a',
  },
  {
    id: 5,
    name: 'Rimil Hansda',
    post: 'Treasurer',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238840/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250217-WA0012_fp1e8g.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/rimil-hansda-702b24256',
  },
  {
    id: 6,
    name: 'Kartik Kumar',
    post: 'Joint Secretary',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238817/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250112-WA0048_ndifsw.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/kartik-kumar-550933298',
  },
  {
    id: 7,
    name: 'Mridula Khushi',
    post: 'Joint Secretary',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238665/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20250112_161200_btkbdo.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/mridula-khushi-7966b0254',
  },
  {
    id: 8,
    name: 'Bhaskar Kumar',
    post: 'Joint Treasurer',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238656/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20250111_152114_ynebr1.png',
    LinkdeIn: 'https://www.linkedin.com/in/bhaskar-kumar-7869312a1',
  },
  {
    id: 9,
    name: 'Aditi Chowdhury',
    post: 'Joint Treasurer',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238825/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250113-WA0033_ylavek.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/aditi-chowdhury-359a61258',
  },
  {
    id: 10,
    name: 'Apurb Mishra',
    post: 'International Services & Web Lead',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238687/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250217-WA0011_trpbdv.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/apurb-mishra-30b41b258',
  },
  {
    id: 11,
    name: 'Rishika singh',
    post: 'Editor in Chief & Community Services',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238791/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250102-WA0011_qdlmrs.jpg',
    LinkdeIn: 'https://in.linkedin.com/in/rishika-singh-032b86256',
  },
  {
    id: 12,
    name: 'Jheel Chourasia',
    post: 'PRO & Alumni Incharge',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238620/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20240626_160330_1_fiooso.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/jheel-chourasia-993359260',
  },
  {
    id: 13,
    name: 'Sanitya Shekhar',
    post: 'Design & Technical Head',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238707/Rotaract_website/Rotaract%20Post%20Bearers/IMG20250112111243_ombch9.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/sanitya-shekhar-1b8a04256',
  },
  {
    id: 14,
    name: 'Shobhit Ranjan',
    post: 'Sergeant-at-Arms',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238868/Rotaract_website/Rotaract%20Post%20Bearers/IMG-20250217-WA0038_ifsaar.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/shobhit-ranjan-126a51283',
  },
  {
    id: 15,
    name: 'Nupur Rajak',
    post: 'Liaison Officer',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740238858/Rotaract_website/Rotaract%20Post%20Bearers/IMG_20250128_223611_mz0z9l.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/nupur-rajak-446a1b291',
  },
];

export const rotoEvents = [
  {
    id: 1,
    date: '3rd February, 2025',
    name: 'Saraswati Puja',
    desc: 'The Rotaract club of BIT Sindri celebrated Vasant Panchami with the students of the college. We performed Saraswati Puja in our club room on February 3,2025. The club members came together to perform Saraswati Puja in club room, which was beautifully decorated for this occcasion.The celebrations were a huge success, and the club members were thrilled to have come together to honour the Goddess of Knowledge.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740248736/Rotaract_website/Rotaract%20Events/Saraswati_puja_2025_ybgkz6.jpg',
  },
  {
    id: 2,
    date: '26th January, 2025',
    name: 'Republic Day',
    desc: 'The Rotaract Club of BIT Sindri successfully celebrated the 76th Republic Day on 26th January with great enthusiasm and patriotism. The event, organized in front of the Chemical Department, was graced by the esteemed presence of Rtn. Ranjit Singh Sir Secretary, Rotary Sindri, Dr. Pankaj Rai Sir, Director of BIT Sindri, S.C. Dutta Sir, Prof. In- charge, several professors, and other distinguished members.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740248931/Rotaract_website/Rotaract%20Events/Republic_day_vdeloc.jpg',
  },
  {
    id: 3,
    date: '12th January, 2025',
    name: 'Vivartan',
    desc: 'The Post Installation Ceremony, Vivartan, took place on 12th January, 2025. The day marked the beginning of a new chapter in the journey of leadership and dedication. With Chief Guest Secretary Ranjeet Sir from Rotary Sindri, it was not just about passing the torch; it was welcoming new post holders. It was a momentous occasion that heralded a promising start to a new era of leadership excellence.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740248946/Rotaract_website/Rotaract%20Events/Vivartan_d9f98p.jpg',
  },
  {
    id: 4,
    date: '15th December, 2024',
    name: 'Cloth Donation Drive',
    desc: `The Rotaract Club of BIT Sindri organized a 'Cloth Donation Drive' on 15th December, 2024, to support underprivileged communities during the harsh winter season. The drive was conducted in various areas of Rohraband, including Ambedkar Colony, RL Colony, and the locality near Seven Lakes. The event was successfully conducted under the guidance of Rtn. Ranjeet Sir, Secretary of Rotary Sindri, and Prof. S.C. Dutta Sir, Prof. In-charge of the club. The campaign brought relief to numerous families, spreading smiles and fostering a sense of solidarity within the community.`,
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249009/Rotaract_website/Rotaract%20Events/Cloth_donation_drive_gxswdm.jpg',
  },
  {
    id: 5,
    date: '3rd December, 2024',
    name: 'World Disability Day Rally',
    desc: 'On the 3rd of December, the Rotaract Club of BIT Sindri had the honor of volunteering at Jeevan Jyoti School for a rally to raise awareness about disabilities. It was a day filled with learning and compassion as we stood alongside the students and staff, spreading a message of acceptance and inclusion.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249035/Rotaract_website/Rotaract%20Events/World_disability_day_m0s1pz.jpg',
  },
  {
    id: 6,
    date: '16th November, 2024',
    name: 'Platinum Jubilee Alumni Meet',
    desc: 'The Rotaract Club of BIT Sindri hosted an inspiring and heartfelt alumni meet at IT Building S-28. This gathering was a wonderful opportunity for alumni to reconnect with old friends, share experiences, and engage with the current generation of Rotaractors. The event kicked off with a warm welcome, highlighting the key and upcoming events of the club and the significant contributions of its past members. The air was filled with nostalgia as 14 alumni recounted their memorable days at BIT Sindri, sharing stories of their time in the club, lessons learned, and the lifelong friendships forged.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249099/Rotaract_website/Rotaract%20Events/Alumni_meet_gtoodx.jpg',
  },
  {
    id: 7,
    date: '30th October, 2024',
    name: 'Karuna',
    desc: 'On the special occasion of Diwali, The Rotaract Club Of BIT Sindri came up with the special idea "Karuna". Through Karuna, our members support small vendors by purchasing local products and share festive cheer by donating sweets to underprivileged children. Every gesture reflects our commitment to uplifting our community and bringing the true essence of Diwali into others lives.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249126/Rotaract_website/Rotaract%20Events/Karuna_ngifye.jpg',
  },
  {
    id: 8,
    date: '20th-22th September, 2024',
    name: 'Mega Limb Camp',
    desc: 'The Rotaract Club of BIT Sindri had the privilege of volunteering at the three-day Mega Limb Camp, organized by Rotary Dhanbad, from September 20th to 22nd, at the Jain Milan Centre, Dhanbad. This life-changing initiative provided artificial limbs and wheelchairs to those in need, restoring mobility, dignity, and hope to countless individuals.As we reflect on our experience at the Mega Limb Camp, we are reminded of the importance of community service and the impact that we can have when we come together to support a noble cause. Together, we can create a more compassionate, inclusive, and empowering world.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249143/Rotaract_website/Rotaract%20Events/Limb_camp_ruablp.jpg',
  },
  {
    id: 9,
    date: '2nd September, 2024',
    name: 'Pankh 3.0',
    desc: 'Sanitary pad distribution is one of the major events conducted by the Rotaract club of BIT Sindri. On 2nd September 2024, it organised the Pankh Event ,where more than 3500 sanitary pads were distributed. It is a crucial initiative to conduct awareness on menstrual hygiene and important topics like good touch and bad touch. This initiative aims to empower young girls to manage their menstruation with dignity.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249161/Rotaract_website/Rotaract%20Events/Pankh_vwmt6r.jpg',
  },
  {
    id: 10,
    date: '15th August, 2024',
    name: '78th Independence Day',
    desc: 'Every year on 15th August, the Rotaract Club of BIT Sindri hoists the National Flag to honour the sacrifices and struggles of the freedom fighters. This event is marked by the presence of many dignitaries along with the members of the club, celebrating it with great enthusiasm and patriotism.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249353/Rotaract_website/Rotaract%20Events/Independence_day_vys48r.jpg',
  },
  {
    id: 11,
    date: '14th August, 2024',
    name: 'Blood Donation Camp: Sindri',
    desc: 'The Rotaract Club of BIT Sindri commemorated 78th Independence Day in a unique and meaningful way. Our members volunteered at a Blood Donation Camp held at SPM College, Sindri, demonstrating their commitment to community service and fostering a spirit of generosity. The Rotaract Club of BIT Sindri experience at the Blood Donation Camp was a truly rewarding one. We are grateful for the opportunity to have served the community and contributed to a noble cause. As we continue to strive for excellence in community service, we remain committed to fostering a spirit of generosity and selflessness among our members.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249233/Rotaract_website/Rotaract%20Events/Blood_donation_1_crsqoq.jpg',
  },
  {
    id: 12,
    date: '20th July, 2024',
    name: 'International Chess Day',
    desc: 'Like every year, the members of Rotaract Club of BIT Sindri came together to celebrate International Chess Day on July 20th, 2024, by wearing black and white to represent the tussle of life.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740249281/Rotaract_website/Rotaract%20Events/Chess_day_scpoel.jpg',
  },
  {
    id: 13,
    date: '1st July, 2024',
    name: 'Plantation Drive',
    desc: 'The Rotaract Club of BIT Sindri welcomed the new Rota Year with a symbolic sapling plantation initiative with the new theme "Be a game changer". This effort reflects our dedication in fostering growth, serving the community, and promoting environmental sustainability.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740253282/Rotaract_website/Rotaract%20Events/plantation_drive_rik6oa.jpg',
  },
  {
    id: 14,
    date: '26th April, 2024',
    name: 'Rudrabhishek Puja',
    desc: 'Rotaract Club of BIT Sindri organized a sacred Rudrabhishek Puja at Shiv Mandir, OP. This traditional puja, performed with reverence and sacred chants, sought to alleviate suffering and foster prosperity, uniting our community in spiritual harmony.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740254048/Rotaract_website/Rotaract%20Events/RUDRABHISHEK_PUJA_ilzalx.jpg',
  },
  {
    id: 15,
    date: '24th April, 2024',
    name: 'Anushilan 3.0',
    desc: 'Final round of Anushilan 3.0 concluded on 24th April. This round composed of buzzer round followed by an exciting rapid fire round. Chemical branch emerged as the overall winner. It was more than just a quiz competition – it was a celebration of knowledge, teamwork, and intellectual curiosity. The Rotaract Club of BIT Sindri is proud to have provided a platform for students to come together, learn from each other, and showcase their talents.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740254132/Rotaract_website/Rotaract%20Events/anushilan_omlcxi.jpg',
  },
  {
    id: 16,
    date: '19th March, 2024',
    name: 'Pre Holi Celebration',
    desc: 'As Holi approached, the Rotaract Club of BIT Sindri came alive with excitement. On March 19, the club organized a Pre-Holi Celebration, where members joyfully smeared each other with colorful gulal and exchanged warm wishes.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1740254049/Rotaract_website/Rotaract%20Events/Pre_holi_celebration_yk1bnm.jpg',
  },
  {
    id: 17,
    date: '14th February, 2024',
    name: 'Saraswati Puja',
    desc: "The Rotaract club of BIT Sindri celebrated Vasant Panchami with the students of the college. We performed Saraswati Puja in our club room on February 14, 2024. The club members came together to perform Saraswati Puja in club room, which was beautifully decorated for this occcasion. The celebrations were a huge success, and the club members were thrilled to have come together to honour the Goddess of Knowledge.",
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1719234571/Rotaract_website/Rotaract%20Events/DocScanner_2_Apr_2024_11-36_am_mu9iu1_sfdeea.jpg',
  },
  {
    id: 18,
    date: '9th-11th February, 2024',
    name: '56th Eye Relief Camp',
    desc: 'The Rotary Sindri and the Rotaract Club of BIT Sindri, supported by SAIL, organized the 56th Eye Relief Camp at CHC Gaushala from 9th to 11th February. The ED of SAIL, Rotarian Ranjit Singh, inaugurated this three-day camp as the chief guest, joined by esteemed SAIL officers and Rotary Sindri members. Around 150+ patients were registered for screening, and the medical team conducted 79 successful cataract operations. The event symbolized the power of collective action and compassion.',
    img: 'https://res.cloudinary.com/dkpgjkabu/image/upload/v1719234577/Rotaract_website/Rotaract%20Events/DocScanner_2_Apr_2024_11-39_am_jehfek_i6kcsy.jpg',
  },
  {
    id: 19,
    date: '26th January, 2024',
    name: '75th Republic Day',
    desc: 'The 75th Republic Day celebration was observed with grandeur and patriotic fervor on January 26, 2024. The event was organised and carried out outside the Metal-Chemical Engineering Department. The day commenced with the hoisting of the national flag by our Director Prof (Dr) Pankaj Rai Sir, Prof. Dr. Ghanshyam Sir and other dignitaries. The tricolor fluttered proudly against the backdrop of the azure sky, evoking a sense of pride and patriotism.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081451/Rotaract%20Events/DocScanner_2_Apr_2024_11-43_am_xp2xit.jpg',
  },
  {
    id: 20,
    date: '16th January, 2024',
    name: 'Interation with Specially-Abled Children',
    desc: `The Rotaract Club of BIT Sindri visited Jeevan Jyoti School on 16th January 2024 with a powerful message: "Abled does not mean enabled. Disabled does not mean less abled." This visit was more than just a casual interaction; it was a testament to the club's commitment to inclusivity and understanding. Through engaging games and activities like musical chairs, throw ball, barriers were broken, and the unique talents of each child were celebrated. This event showcased the belief that every child, regardless of their challenges, deserves recognition and equal opportunities.`,
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081461/Rotaract%20Events/DocScanner_2_Apr_2024_11-33_am_ooab6n.jpg',
  },
  {
    id: 21,
    date: '17th December, 2023',
    name: 'Vivartan',
    desc: "The Post Installation Ceremony, Vivartan, took place on December 17th, 2023. The day marked the beginning of a new chapter in the journey of leadership and dedication. With Chief Guest Secretary Ranjeet Sir from Rotary Sindri, it wasn't just about passing the torch; it was welcoming new post holders. It was a momentous occasion that heralded a promising start to a new era of leadership excellence.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081459/Rotaract%20Events/DocScanner_2_Apr_2024_11-42_am_mm4c82.jpg',
  },
  {
    id: 22,
    date: '3rd December, 2023',
    name: 'World Disability Day Rally',
    desc: 'The Rotaract  Club of BIT Sindri, with Rotary Club of Dhanbad, organized a "DISABILITY AWARENESS RALLY & CULTURAL PROGRAM" on World Disability Day. Chief Guest Shri Ravi Raj Sharma and Guest of Honor Shri Anup Kumar joined us to promote inclusivity and equal opportunities for specially-abled children from Jeevan Jyoti School.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081417/Rotaract%20Events/DocScanner_2_Apr_2024_11-40_am_pg75gf.jpg',
  },
  {
    id: 23,
    date: '12th November, 2023',
    name: 'Karuna',
    desc: 'On the special occasion of Diwali, The Rotaract Club Of BIT Sindri came up with the special idea "Karuna". On 12th November 2023, our team embraced the true spirit of Diwali by purchasing from small vendors, distributing sweets to children, making donations to orphans and many more. We seek to create more positive impacts in our society in upcoming future.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081526/Rotaract%20Events/DocScanner_2_Apr_2024_11-37_am_wuzpue.jpg',
  },
  {
    id: 24,
    date: '11th September, 2023',
    name: 'Pankh Phase II',
    desc: 'The Rotaract club of BIT Sindri concluded its annual sanitary pad distribution and awareness drive for underprivileged girls at Rajkiya Buniyadi Vidyalaya, BIT Sindri Campus. The members themselves spoke on the topic and provided proper knowledge and guidance about periods and sanitary pads to the students. A total of 400 pads were distributed free of charge among the girls. Receiving a fruitful response and interaction with the audience made us feel like all our hard work had paid off.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081408/Rotaract%20Events/IMG-20240402-WA0010_ilaxkc.jpg',
  },
  {
    id: 25,
    date: '1st to 3rd September, 2023',
    name: 'Mega Limb Camp, Dhanbad',
    desc: 'Members of the Rotaract Club of BIT Sindri volunteered in the Mega Limb Camp, which was organised by the Gerry Powell Jaipur Centre at Jain Milan, Jodaphatak, Dhanbad. This event is a signature initiative of the Rotary Club of Dhanbad. More than 130 artificial limbs were provided to the patients.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081429/Rotaract%20Events/IMG-20240402-WA0012_c1ymqd.jpg',
  },
  {
    id: 26,
    date: '10th September, 2023',
    name: 'Mega Health Chekup Camp, Tundi',
    desc: 'Members of the Rotaract Club of BIT Sindri volunteered at the Mega Health Camp at Swarup Saraswati Vidya Mandir in Ratanpur, Tundi. The camp was organized by the Rotary Club of Dhanbad. The event aimed to provide free healthcare services to the school and local community, emphasizing preventive care and health awareness. Beneficiaries were offered a wide range of essential medical services, including general health checks, dental, eye, skin, blood, and many more checkups. Free medicines were also provided to attendees, ensuring they had access to necessary medications. More than 600 individuals benefited from the services provided.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522523/Rotaract%20Events/tundi_camp_iydixq.jpg',
  },
  {
    id: 27,
    date: '21st August, 2023',
    name: 'Pankh Phase I',
    desc: 'The Rotaract club of BIT Sindri organised its annual Sanitary Pad distribution and awareness drive for underprivileged girls. Members visited three different schools, starting from DAV Public School, Rangamati, to Utkramit High School, Rangamati, and DAV High School, Tasra. The members themselves spoke on the topic and provided proper knowledge and guidance about periods and sanitary pads to the students. A total of 3,000 pads were distributed free of charge among the girls. Receiving a fruitful response and interaction with the audience made us feel like all our hard work paid off.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522236/Rotaract%20Events/Pankh_I_mbpmae.jpg',
  },
  {
    id: 28,
    date: '14th August, 2023',
    name: 'Blood Donation Camp: Sindri',
    desc: 'The members of the Rotaract club of BIT Sindri volunteered at the blood donation camp organised by Jan Adhikar Manch, Sindri, on August 14. The camp was organised at Dr. Shyama Prasad Mukherjee Inter College, Goshala, Sindri. The chief guest, MLA Jharia Purnima Niraj Singh, inaugurated the camp. Registrations were done, and the donors were served refreshments and given proper care. The exceptional thing about this camp was that it had a target of 77 donations to complete. The target was kept on account of the completion of 77 years of independence and was completed before 4 pm.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522220/Rotaract%20Events/blood_donation_spm_ekmoen.jpg',
  },
  {
    id: 29,
    date: '15th August, 2023',
    name: '77th Independence Day Celebration',
    desc: 'Rotaract Club of BIT Sindri paid tribute to the freedom fighters of India by hoisting the national flag and remembering their valour and hardships to fulfill the dream of an independent nation. With the theme of Nation First, Always First, we celebrated the 77th Independence Day.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522234/Rotaract%20Events/Independence_day_2023_e5uff6.jpg',
  },
  {
    id: 30,
    date: '12th June, 2023',
    name: 'World Against Child Labour Awareness Campaign',
    desc: "Small hand can handle a pen better. Lend your support to end child labour.Child labour is the exploitation of children who are deprived of their childhood by work. This prevents them from attending school and causes physical, mental and social harm.Taking an initiative to stop this trauma, Rotaract Club of BIT Sindri is spreading awareness by pinning posters at different cities of Jharkhand. The posters were designed to make people aware about a child's education and progress by not making them work.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522231/Rotaract%20Events/child_labour_day_pcyu7d.jpg',
  },


  
];
